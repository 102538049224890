<template>
  <LocalizationSettingsView
    :language="$store.getters.language.lang"
    :platform="$store.getters.platform"
    @back="$router.go(-1)"
    :function="submit"
    origin="SPECIALIST"
  />
</template>

<script>
import { LocalizationSettingsView } from '@seliaco/red-panda'
export default {
  name: 'LocalizationSettingsContainer',
  components: {
    LocalizationSettingsView
  },
  methods: {
    submit (item) {
      this.$store.commit('auth/SET_USER', {
        ...this.$store.getters['auth/user'],
        time_zone: item.timezone,
        country: item.country
      })
      this.$store.dispatch('SET_LOCALIZATION')
    }
  }
}
</script>
